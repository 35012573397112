import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueClipboard from 'vue-clipboard2'
import VueCompositionAPI from '@vue/composition-api'
import { setRequestHeader } from '@/libs/axios'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

import { isUserLoggedIn } from './auth/utils'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueClipboard)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

require('@core/assets/fonts/feather/iconfont.css')

// import assets styles
require('@/assets/scss/style.scss')

const vueInstance = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

const handleAuth = async appInstance => {
  console.log(
    window.location.pathname,
    window.location.pathname.startsWith('/partner')
  )
  if (isUserLoggedIn()) {
    setRequestHeader(isUserLoggedIn())
  } else if (
    window.location.pathname !== '/register' &&
    !window.location.pathname.startsWith('/partner')
  ) {
    appInstance.$router.push('/')
  }
}

handleAuth(vueInstance)

Vue.config.productionTip = false
