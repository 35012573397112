import Vue from 'vue'
import axios from 'axios'

// axios

const baseURL = `${process.env.VUE_APP_API_ENDPOINT}/api/v1`

// const baseURL = 'http://0.0.0.0:3338/api/v1'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.response.use(
  res => res,
  err => {
    switch (err.response.status) {
      case 401:
        alert(
          'ขออภัยระบบขัดข้อง กรุณารอสักครู่แล้วลองใหม่อีกครั้งค่ะ ขออภับในความไม่สะดวกค่ะ'
        )
        break
      default:
        console.log('default', err.response.status)
    }
  }
)

// // Request Interceptor
// this.axiosIns.interceptors.request.use(
//   config => {
//     // Get token from localStorage
//     const accessToken = jwt.getToken()

//     // If token is present add it to request's Authorization Header
//     if (accessToken) {
//       // eslint-disable-next-line no-param-reassign
//       config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
//     }
//     return config
//   },
//   error => Promise.reject(error)
// )
export function setRequestHeader(key, value) {
  axiosIns.defaults.headers.common[key] = value
}

Vue.prototype.$http = axiosIns

export default axiosIns
